'use client';

import {FC} from 'react';
import useGdprCookieBar from 'modules/state/app/hook/gdpr/useCookieBar';
import useAnalyticsSender from 'modules/state/app/hook/analytics/useSender';
import Gemius from 'components/analytics/Gemius';
import GoogleTagManager from 'components/analytics/GoogleTagManager';
import Livemonitor from 'components/analytics/Livemonitor';
import dynamic from 'next/dynamic';

const FacebookPixel = dynamic(() => import('./FacebookPixel'), { ssr: false });
const RtbHouse = dynamic(() => import('./RtbHouse'), { ssr: false });

const Main: FC = () => {
    useAnalyticsSender();
    const { isAllowed: { googleTagManager, facebookPixel, rtbHouse, livemonitor } } = useGdprCookieBar();

    return <>
        {googleTagManager && <GoogleTagManager />}
        {facebookPixel && <FacebookPixel />}
        <Gemius /> {/* This is OK */}
        {rtbHouse && <RtbHouse />}
        {livemonitor && <Livemonitor />}
    </>;
};


export default Main;
